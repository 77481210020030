<template>
  <app-side-bar
    v-model="open"
    title="Verbindung bearbeiten"
    width="500"
  >
    <template #actions>
      <v-btn
        icon
        data-cy="deleteEdge"
        @click="$emit('delete')"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <v-container v-if="modelValue">
      <v-row>
        <v-col>
          <schedule-flow-requirement-list
            v-if="modelValue?.id"
            collection="schedule_flow_edge"
            :item="modelValue.id"
            :facility-id="facilityId"
            experience-only
          />
        </v-col>
      </v-row>
    </v-container>
  </app-side-bar>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    facilityId: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue', 'delete'],
  computed: {
    open: {
      get() {
        return this.modelValue !== null
      },
      set(value) {
        if (!value) {
          this.$emit('update:modelValue', null)
        }
      },
    },
  },
}
</script>
