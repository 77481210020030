<template>
  <app-side-bar
    v-model="open"
    :title="title"
    width="500"
  >
    <template #actions>
      <v-btn
        icon
        data-cy="deleteNode"
        @click="$emit('delete')"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <schedule-flow-form-start
      v-if="modelValue?.type === 'start'"
      :model-value="modelValue.data"
      @update:model-value="$emit('update:modelValue', { ...modelValue, data: $event })"
    />
    <schedule-flow-form-unit
      v-if="modelValue?.type === 'unit'"
      :ref="'unitForm-' + modelValue?.id"
      :model-value="modelValue.data"
      :facility-id="facilityId"
      @update:model-value="$emit('update:modelValue', { ...modelValue, data: $event })"
      @update-unit="$emit('updateUnit')"
    />
    <schedule-flow-form-external
      v-if="modelValue?.type === 'external'"
      :facility-id="facilityId"
      :model-value="modelValue.data"
      @update:model-value="$emit('update:modelValue', { ...modelValue, data: $event })"
    />
    <v-container v-if="modelValue?.type === 'external'">
      <v-row>
        <v-col>
          <schedule-flow-requirement-list
            v-if="modelValue?.id"
            collection="schedule_flow_node"
            :item="modelValue.id"
            :facility-id="facilityId"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="modelValue?.type === 'end'">
      <v-row>
        <v-col>
          <schedule-flow-requirement-cards
            v-if="modelValue?.id"
            collection="schedule_flow_node"
            :item="modelValue.id"
            :facility-id="facilityId"
          />
        </v-col>
      </v-row>
    </v-container>
  </app-side-bar>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    facilityId: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue', 'delete', 'updateUnit'],
  data() {
    return {}
  },
  computed: {
    title() {
      switch (this.modelValue?.type) {
        case 'start':
          return 'Startpunkt bearbeiten'
        case 'rotation':
          return 'Rotationsblock bearbeiten'
        case 'unit':
          return 'Einheit bearbeiten'
        case 'external':
          return 'Rotation bearbeiten'
        default:
          return 'Element bearbeiten'
      }
    },
    open: {
      get() {
        return this.modelValue !== null
      },
      set(value) {
        if (!value) {
          if (this.modelValue?.type === 'unit') {
            this.$refs['unitForm-' + this.modelValue?.id].submit()
          }
          this.$emit('update:modelValue', null)
        }
      },
    },
  },
}
</script>
