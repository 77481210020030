<template>
  <v-form>
    <v-row dense>
      <v-col cols="4">
        <v-text-field
          v-model="unit.short_name"
          label="Kürzel"
          data-cy="unitShortName"
          variant="outlined"
          hide-details="auto"
          :error="v$.unit.short_name.$error"
          @blur="v$.unit.short_name.$touch()"
        />
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="unit.long_name"
          label="Name"
          data-cy="unitName"
          variant="outlined"
          hide-details="auto"
          :error="v$.unit.long_name.$error"
          @blur="v$.unit.long_name.$touch()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <select-speciality
          v-model="unit.specialities"
          label="Fachrichtung"
          variant="outlined"
          data-cy="chooseSpeciality"
          hide-details="auto"
          multiple
          field-name="speciality_id"
          :error="v$.unit.specialities.$error"
          @blur="v$.unit.specialities.$touch()"
        />
      </v-col>
      <v-col cols="12">
        <select-rotation-type
          v-model="unit.rotation_types"
          label="Einheitstypen"
          variant="outlined"
          data-cy="chooseRotationType"
          chips
          multiple
          with-default
          hide-details="auto"
          :error="v$.unit.rotation_types.$error"
          @blur="v$.unit.rotation_types.$touch()"
        />
      </v-col>
      <!--v-col cols="12">
        <v-radio-group
          v-model="unit.type"
          data-cy="entryUnitType"
          direction="horizontal"
          inline
          hide-details="auto"
        >
          <v-radio
            label="stationär"
            value="INPATIENT"
          />
          <v-radio
            label="ambulant"
            value="OUTPATIENT"
          />
        </v-radio-group>
      </v-col-->
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          v-model.number="unit.minimum_vacancies"
          label="Mindestbesetzung"
          data-cy="minimumVacancies"
          variant="outlined"
          type="number"
          step="0.1"
          hide-details="auto"
          :error="v$.unit.minimum_vacancies.$error"
          @blur="v$.unit.minimum_vacancies.$touch()"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model.number="unit.maximum_vacancies"
          label="Maximalbesetzung"
          data-cy="maximumVacancies"
          variant="outlined"
          type="number"
          step="0.1"
          hide-details="auto"
          :error="v$.unit.maximum_vacancies.$error"
          @blur="v$.unit.maximum_vacancies.$touch()"
        />
      </v-col>
      <!--v-col>
        <v-text-field
          v-model="unit.priority"
          label="Besetzungspriorität"
          data-cy="unitPriority"
          variant="outlined"
          type="number"
          step="1"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12">
        <v-switch
          v-model="unit.takes_external_rotations"
          variant="outlined"
          class="mt-0"
          data-cy="toggleExternalRotations"
          hide-details="auto"
          label="Offen für externe Rotanden"
        />
      </v-col-->
    </v-row>
    <v-row dense v-if="getPremiumFeatures().includes('MATERNITY_PROTECTION')">
      <v-col cols="12">
        <v-checkbox
          hide-details="auto"
          color="primary-darken"
          v-model="unit.blocked_for_maternity"
          label="Personal in Mutterschutz nicht zulässig"
          data-cy="blockedForMaternity"
          @blur="v$.unit.blocked_for_maternity.$touch()"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"
        ><schedule-flow-requirement-list
          v-if="unit.id"
          collection="unit"
          :item="unit.id"
          :facility-id="facilityId"
        />
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return { speciality: {} }
      },
    },
    facilityId: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      unit: {
        short_name: { required },
        long_name: { required },
        specialities: { required },
        rotation_types: { required },
        minimum_vacancies: { required },
        maximum_vacancies: { required },
        blocked_for_maternity: { default: false },
      },
    }
  },
  data() {
    return {
      unit: this.modelValue,
    }
  },
  watch: {
    unit: {
      handler() {
        this.handleInput()
      },
      deep: true,
    },
  },
  methods: {
    handleInput() {
      this.$emit('update:modelValue', this.unit)
    },
    validate() {
      this.v$.$touch()
      return !this.v$.$invalid
    },
  },
}
</script>
