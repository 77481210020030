<template>
  <v-combobox
    :model-value="modelValue"
    :items="rotationTypes"
    v-bind="$attrs"
    @update:model-value="$emit('update:modelValue', $event)"
  >
  </v-combobox>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: [Array, String],
      default: null,
    },
    facilityId: {
      type: String,
      default: null,
    },
    withDefault: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      rotationTypes: [],
    }
  },
  async mounted() {
    this.rotationTypes = await this.getUnitTypes(this.facilityId, this.withDefault)
  },
}
</script>
