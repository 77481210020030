<template>
  <div class="text-caption">{{ label }}</div>
  <v-slider
    v-model="points"
    class="mb-4"
    min="0"
    max="10"
    step="1"
    show-ticks
    hide-details="auto"
    @end="$emit('change')"
  />
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'change'],
  computed: {
    points: {
      get() {
        return this.modelValue
      },
      set(value) {
        return this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>
