<template>
  <v-row dense>
    <v-col cols="12">
      <p class="text-subtitle-1">Weiterbildungsabschnitte</p>
      <v-divider />
    </v-col>
    <v-col
      v-for="requirement in requirements"
      :key="requirement.id"
      cols="12"
      data-cy="requirementButtons"
    >
      <v-btn
        class="mt-2 py-8"
        block
        color="primary-darken"
        variant="outlined"
        @click="editRequirement = requirement"
      >
        {{ getRequirementTitle(requirement, 0) }}
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-btn
        class="mt-2 py-8 add-link"
        variant="outlined"
        block
        prepend-icon="mdi-plus-circle-outline"
        data-cy="addRequirement"
        @click="editRequirement = { group: 1 }"
      >
        Neuen Abschnitt hinzufügen
      </v-btn>
    </v-col>
  </v-row>

  <schedule-flow-sidebar-requirement
    v-model="editRequirement"
    :collection="collection"
    :item="item"
    :facility-id="facilityId"
    experience-only
    speciality-only
    hide-external
    hide-group
    @update:model-value="loadData"
  ></schedule-flow-sidebar-requirement>
</template>
<script>
export default {
  props: {
    collection: {
      type: String,
      required: true,
    },
    item: {
      type: [String, Number],
      required: true,
    },
    facilityId: {
      type: String,
      default: null,
    },
  },
  emits: ['change'],
  data() {
    return {
      requirementFields: [
        'id',
        'minimum_users',
        'minimum_months',
        'minimum_percentage',
        'minimum_duration',
        'units.unit_id.id',
        'units.unit_id.short_name',
        'units.unit_id.long_name',
        'rotation_types',
        'specialities.speciality_id.id',
        'specialities.speciality_id.name',
        'facilities.facility_id.id',
        'facilities.facility_id.name',
        'group',
        'label.id',
        'label.name',
      ],
      requirements: [],
      editRequirement: null,
    }
  },
  async mounted() {
    this.loadData()
    this.$bus.$on('update:' + this.collection + ':' + this.item, this.loadData)
  },
  methods: {
    async loadData() {
      if (!this.isNew) {
        await this.$cms
          .request(
            this.$readItems('schedule_requirements', {
              filter: {
                target: {
                  ['item:' + this.collection]: {
                    id: {
                      _eq: this.item,
                    },
                  },
                },
              },
              fields: this.requirementFields,
              sort: ['group'],
            })
          )
          .then((response) => {
            this.requirements = response
          })
      }
    },
  },
}
</script>
