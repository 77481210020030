<template>
  <v-row dense>
    <v-col cols="12">
      <p class="text-subtitle-1">Bedingungen</p>
      <v-divider />
    </v-col>
    <v-col v-if="!isNew">
      <v-list
        v-for="(group, groupIndex) in Object.values(groupedRequirements)"
        :key="'group-' + groupIndex"
        class="py-0"
        data-cy="requirementList"
      >
        <v-list-item
          v-for="(requirement, rindex) in group"
          :key="requirement.id"
          :title="getRequirementTitle(requirement, rindex)"
          data-cy="requirementListItem"
          :subtitle="
            requirement.minimum_users
              ? `mindestens ${requirement.minimum_users} Person${
                  requirement.minimum_users > 1 ? 'en' : ''
                }`
              : 'alle Personen'
          "
          @click="editRequirement = requirement"
        >
          <template #append>
            <v-btn
              icon
              variant="flat"
              data-cy="deleteRequirement"
              @click.stop="deleteRequirement(requirement)"
              ><v-icon color="error">mdi-delete</v-icon></v-btn
            >
          </template>
        </v-list-item>

        <v-row
          v-if="groupIndex < Object.keys(groupedRequirements).length - 1"
          align="center"
          dense
        >
          <v-col><v-divider /></v-col>
          <v-col cols="auto">oder</v-col>
          <v-col><v-divider /></v-col>
        </v-row>
      </v-list>
    </v-col>

    <v-col
      v-if="!isNew"
      cols="12"
    >
      <v-btn
        color="primary"
        variant="tonal"
        data-cy="editRequirement"
        @click="editRequirement = { group: 1 }"
      >
        Bedingung hinzufügen
      </v-btn>
    </v-col>
    <v-col
      v-else
      cols="12"
    >
      <v-alert
        class="mt-2"
        type="info"
        color="primary"
        hide-details="auto"
        density="compact"
        variant="plain"
      >
        Bedingungen können hinzugefügt werden, sobald der Ablauf gespeichert wurde.
      </v-alert>
    </v-col>
  </v-row>

  <schedule-flow-sidebar-requirement
    v-model="editRequirement"
    :collection="collection"
    :item="item"
    :facility-id="facilityId"
    :experience-only="experienceOnly"
    :hide-external="hideExternal"
    @update:model-value="loadData"
  ></schedule-flow-sidebar-requirement>
</template>
<script>
export default {
  props: {
    collection: {
      type: String,
      required: true,
    },
    item: {
      type: [String, Number],
      required: true,
    },
    facilityId: {
      type: String,
      default: null,
    },
    experienceOnly: {
      type: Boolean,
    },
    hideExternal: {
      type: Boolean,
    },
  },
  emits: ['change'],
  data() {
    return {
      requirementFields: [
        'id',
        'minimum_users',
        'minimum_months',
        'minimum_percentage',
        'minimum_duration',
        'units.unit_id.id',
        'units.unit_id.short_name',
        'units.unit_id.long_name',
        'rotation_types',
        'specialities.speciality_id.id',
        'specialities.speciality_id.name',
        'facilities.facility_id.id',
        'facilities.facility_id.name',
        'group',
        'label.id',
        'label.name',
      ],
      requirements: [],
      editRequirement: null,
    }
  },
  computed: {
    isNew() {
      return this.item.startsWith('-new-')
    },
    groupedRequirements() {
      return this.requirements.reduce(function (r, a) {
        r[a.group] = r[a.group] || []
        r[a.group].push(a)
        return r
      }, Object.create(null))
    },
  },
  async mounted() {
    this.loadData()
    this.$bus.$on('update:' + this.collection + ':' + this.item, this.loadData)
  },
  methods: {
    async loadData() {
      if (!this.isNew) {
        await this.$cms
          .request(
            this.$readItems('schedule_requirements', {
              filter: {
                target: {
                  ['item:' + this.collection]: {
                    id: {
                      _eq: this.item,
                    },
                  },
                },
              },
              fields: this.requirementFields,
              sort: ['group'],
            })
          )
          .then((response) => {
            this.requirements = response
          })
      }
    },
    async deleteRequirement(requirement) {
      await this.$cms.request(this.$deleteItem('schedule_requirements', requirement.id))
      this.requirements = this.requirements.filter((req) => req.id !== requirement.id)
      this.$bus.$emit('update:' + this.collection + ':' + this.item)
    },
  },
}
</script>
