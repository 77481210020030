<template>
  <v-container v-if="modelValue">
    <v-row>
      <v-col>
        <v-text-field
          :model-value="modelValue.priority"
          type="number"
          step="1"
          label="Prio."
          data-cy="prio"
          variant="outlined"
          @update:model-value="$emit('update:modelValue', { ...modelValue, priority: $event })"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <select-facility
          :selected="modelValue.facilities"
          selectable
          label="Kliniken"
          data-cy="selectModelFacilities"
          variant="outlined"
          return-object
          @update:selected="$emit('update:modelValue', { ...modelValue, facilities: $event })"
        >
        </select-facility>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field
          :model-value="modelValue.minimumVacancies"
          label="Min."
          variant="outlined"
          type="number"
          data-cy="minVacancies"
          step="0.1"
          min="0"
          @update:model-value="
            $emit('update:modelValue', { ...modelValue, minimumVacancies: $event })
          "
        >
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          :model-value="modelValue.maximumVacancies"
          label="Max."
          variant="outlined"
          type="number"
          data-cy="maxVacancies"
          step="0.1"
          min="0"
          @update:model-value="
            $emit('update:modelValue', { ...modelValue, maximumVacancies: $event })
          "
        >
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    facilityId: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {}
  },
}
</script>
