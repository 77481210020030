<template>
  <v-container v-if="modelValue">
    <v-row>
      <v-col>
        <v-text-field
          :model-value="modelValue.priority"
          type="number"
          step="1"
          label="Prio."
          variant="outlined"
          hide-details="auto"
          data-cy="prio"
          @update:model-value="$emit('update:modelValue', { ...modelValue, priority: $event })"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs
          v-model="tab"
          grow
          color="primary"
          data-cy="unitTabs"
        >
          <v-tab value="unit">Einheit</v-tab>
          <v-tab value="rotationType">Einheitstyp</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-window v-model="tab">
          <v-window-item
            :transition="false"
            eager
            value="unit"
          >
            <v-row>
              <v-col>
                <select-unit
                  class="mt-2"
                  :model-value="modelValue.unit"
                  label="Einheit"
                  variant="outlined"
                  return-object
                  :facility-id="facilityId"
                  clearable
                  data-cy="selectUnit"
                  @update:model-value="$emit('update:modelValue', { ...modelValue, unit: $event })"
                >
                </select-unit>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <p class="text-subtitle-1">Generelle Einstellungen der Einheit</p>
                <v-divider />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <facility-unit-form
                  v-if="modelValue.unit"
                  :key="modelValue.unit"
                  class="mt-2"
                  :model-value="modelValue.unit"
                  :facility-id="facilityId"
                  clearable
                  @update:model-value="updateUnit"
                />
              </v-col>
            </v-row>
          </v-window-item>

          <v-window-item
            :transition="false"
            eager
            value="rotationType"
          >
            <v-row>
              <v-col>
                <select-rotation-type
                  class="mt-2"
                  :model-value="modelValue.rotationType"
                  label="Einheitstyp"
                  variant="outlined"
                  data-cy="rotationType"
                  :facility-id="facilityId"
                  @update:model-value="
                    $emit('update:modelValue', { ...modelValue, rotationType: $event })
                  "
                />
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
    facilityId: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue', 'updateUnit'],
  data() {
    return {
      tab: 'unit',
    }
  },
  mounted() {
    if (this.modelValue.rotation_type) {
      this.tab = 'rotationType'
    }
  },
  methods: {
    async submit() {
      if (this.modelValue.unit && this.modelValue.unit.id) {
        await this.$cms.request(
          this.$updateItem('unit', this.modelValue.unit.id, {
            short_name: this.modelValue.unit.short_name,
            long_name: this.modelValue.unit.long_name,
            specialities: this.modelValue.unit.specialities.map((speciality) => {
              return { speciality_id: speciality.speciality_id.id }
            }),
            type: this.modelValue.unit.type,
            rotation_types: this.modelValue.unit.rotation_types,
            minimum_vacancies: this.modelValue.unit.minimum_vacancies,
            maximum_vacancies: this.modelValue.unit.maximum_vacancies,
            blocked_for_maternity: this.modelValue.unit.blocked_for_maternity,
          })
        )
      }
    },
    updateUnit(event) {
      this.$emit('update:modelValue', { ...this.modelValue, unit: event })
      this.$emit('updateUnit')
    },
  },
}
</script>
