<template>
  <v-container v-if="modelValue">
    <v-row>
      <v-col>
        <v-select
          :model-value="modelValue.type"
          variant="outlined"
          data-cy="startPointType"
          :items="types"
          @update:model-value="$emit('update:modelValue', { ...modelValue, type: $event })"
        >
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      types: [
        {
          title: 'Standard',
          value: null,
        },
        {
          title: 'Außenrotanden',
          value: 'external',
        },
      ],
    }
  },
}
</script>
