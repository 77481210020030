<template>
  <div>
    <v-card
      title="Ende"
      class="pr-12 pb-8"
      color="success"
      density="compact"
      data-cy="endPoint"
    >
    </v-card>
    <Handle
      id="a"
      type="source"
      :position="Position.Top"
    />
  </div>
</template>
<script setup>
import { Handle, Position } from '@vue-flow/core'
</script>
<script>
export default {
  props: {
    type: {
      type: String,
      default: null,
    },
  },
}
</script>
