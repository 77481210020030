<template>
  <app-side-bar
    v-model="open"
    title="Weiterbildungsverlauf laut Algorithmus"
    width="400"
    location="left"
    :temporary="false"
    order="10"
  >
    <v-progress-linear
      v-show="loading"
      color="accent"
      indeterminate
    ></v-progress-linear>
    <v-container v-if="modelValue">
      <v-row
        v-for="(entry, i) in history"
        :key="'entry' + i"
        align="center"
      >
        <v-col cols="1"> {{ i + 1 }}. </v-col>
        <v-col cols="11">
          <v-card
            variant="outlined"
            :color="entry.facility ? 'accent' : 'primary'"
            @click="openPointsDialog(entry)"
          >
            <v-card-text>
              <strong>
                {{ entry.facility ? getFacilityName(entry.facility) : getUnitName(entry.unit) }}
              </strong>
              <span v-if="entry.rotation_type"> - {{ entry.rotation_type }}</span>
              <span v-if="entry.specialities && entry.specialities.length">
                &nbsp;({{
                  entry.specialities
                    .map((spec) => getSpecialityName(spec.speciality_id))
                    .join(', ')
                }})
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </app-side-bar>

  <v-dialog
    v-model="pointsDialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          data-cy="closePointsDialog"
          @click="pointsDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Punktevergabe</v-toolbar-title>
      </v-toolbar>
      <rotationplan-points-matrix :points="points" />
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
    },
    facilityId: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      loading: false,
      facility: null,
      history: [],
      specialities: [],
      pointsDialog: false,
      points: null,
    }
  },
  computed: {
    open: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  async mounted() {
    this.specialities = await this.$cms.request(
      this.$readItems('speciality', {
        filter: {
          profession: {
            _eq: 'PHYSICIAN',
          },
        },
        fields: ['id', 'name'],
      })
    )
    this.loadData()
  },
  methods: {
    async loadData() {
      this.loading = true
      const response = await this.$cms.request(() => {
        return {
          method: 'GET',
          path:
            '/custom-schedule/history/default?' +
            new URLSearchParams({
              facilityId: this.facilityId,
            }),
        }
      })
      if (response?.defaultEntries) {
        this.facility = response.facility
        this.history = response.defaultEntries.map((entry) => {
          entry.points = { pointMatrix: entry.pointMatrix, ...this.facility }
          return entry
        })
      }
      this.loading = false
    },
    getFacilityName(facilityId) {
      const node = this.facility.flow.nodes.find((node) =>
        node.facilities.some((f) => f.facility_id.id === facilityId)
      )
      const externalFacility = node.facilities.find(
        (f) => f.facility_id.id === facilityId
      ).facility_id
      return externalFacility.name
    },
    getUnitName(unitId) {
      const unit = this.facility.units.find((unit) => unit.id === unitId)
      return unit.short_name
    },
    getSpecialityName(specialityId) {
      const speciality = this.specialities.find((spec) => spec.id === specialityId)
      return speciality ? speciality.name : ''
    },
    openPointsDialog(entry) {
      this.points = entry.points
      this.pointsDialog = true
    },
  },
}
</script>
