<template>
  <v-autocomplete
    id="label-select"
    :model-value="modelValue"
    label="Label"
    :items="labels"
    item-title="name"
    item-value="id"
    variant="outlined"
    :loading="loading"
    autocomplete="off"
    clear-on-select
    @update:model-value="updateModelValue($event)"
  >
    <template #append>
      <v-btn
        append-icon="mdi-circle-edit-outline"
        color="primary-darken"
        size="large"
        data-cy="manageLabels"
        rounded
        @click="openSidebar"
      >
        <span v-if="$vuetify.display.mobile"> Verwalten </span>
        <span v-else> Labels verwalten </span>
      </v-btn>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    facilityId: {
      type: String,
      default: null,
    },
    returnObject: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      label_id: '',
      labels: [],
      loading: false,
    }
  },
  watch: {
    async '$sidebarStore.labelEdit.active'(value) {
      if (!value) {
        this.loadData().then(() => {
          if (!this.labels.some((el) => el.id == this.modelValue)) {
            this.$emit('update:modelValue', null)
          }
        })
      }
    },
  },
  mounted() {
    this.loadData()
  },
  methods: {
    updateModelValue(value) {
      let selectedLabel = value
      if (this.returnObject) {
        selectedLabel = this.labels.find((l) => l.id === value) ?? null
      }
      this.$emit('update:modelValue', selectedLabel)
    },
    loadData() {
      this.loading = true
      return this.$cms
        .request(
          this.$readItems('labels', {
            filter: {
              facility: {
                _eq: this.facilityId,
              },
            },
            fields: ['id', 'name'],
          })
        )
        .then((res) => {
          this.labels = res
        })
        .then(() => (this.loading = false))
    },
    openSidebar(e) {
      this.$sidebarStore.open({
        type: 'labelEdit',
        facilityId: this.facilityId,
      })
    },
  },
}
</script>
